import { Slice } from "gatsby"
import React from "react"
import Transition from "../components/Layout/Transition"
import ParallaxRouteUpdate from "../components/ParallaxRouteUpdate"
import SvgSprite from "../components/SvgSprite"
import SvgSpriteButtons from "../components/SvgSpriteButtons"

interface Props {
  children: React.ReactNode
  location: Location
  pageContext?: any
}

const defaultProps = {
  pageContext: {},
}

const Layout = ({ children, location, pageContext }: Props) => {
  // PDF generation pages - no header or footer
  if (pageContext.isPDF)
    return (
      <>
        {children}
        <SvgSprite />
        <SvgSpriteButtons />
      </>
    )

  return (
    <>
      <Slice alias="header" locationKey={(location as any)?.key} />
      <Transition location={location}>{children}</Transition>
      <Slice alias="footer" />
      <SvgSprite />
      <SvgSpriteButtons />
      <ParallaxRouteUpdate location={location} />
    </>
  )
}

Layout.defaultProps = defaultProps

export default Layout
