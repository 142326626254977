import React from "react"

interface Props {}

const defaultProps = {}

export const SvgSpriteButtons: React.FC<Props> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="visuallyHidden"
    aria-hidden="true"
  >
    <symbol id="icon-caretDownBtn" viewBox="0 0 32 33">
      <path
        d="M8 12.5L16 20.5L24 12.5"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </symbol>

    <symbol id="icon-caretLeftBtn" viewBox="0 0 31 32">
      <g transform="rotate(180) translate(-31, -32)">
        <path
          d="M11.6245 23.75L19.3745 16L11.6245 8.25"
          strokeWidth="2"
          strokeLinecap="square"
        />
      </g>
    </symbol>

    <symbol id="icon-caretRightBtn" viewBox="0 0 31 32">
      <path
        d="M11.6245 23.75L19.3745 16L11.6245 8.25"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </symbol>

    <symbol id="icon-caretUpBtn" viewBox="0 0 32 33">
      <g transform="rotate(180) translate(-32, -33)">
        <path
          d="M8 12.5L16 20.5L24 12.5"
          strokeWidth="2"
          strokeLinecap="square"
        />
      </g>
    </symbol>

    <symbol id="icon-documentBtn" viewBox="0 0 25 25">
      <path d="M14.3702 5.78711L19.2002 10.6171V19.7871H5.2002V5.78711H14.3702ZM14.3702 3.78711H5.2002C4.1002 3.78711 3.2002 4.68711 3.2002 5.78711V19.7871C3.2002 20.8871 4.1002 21.7871 5.2002 21.7871H19.2002C20.3002 21.7871 21.2002 20.8871 21.2002 19.7871V10.6171C21.2002 10.0871 20.9902 9.57711 20.6102 9.20711L15.7802 4.37711C15.4102 3.99711 14.9002 3.78711 14.3702 3.78711ZM7.2002 15.7871H17.2002V17.7871H7.2002V15.7871ZM7.2002 11.7871H17.2002V13.7871H7.2002V11.7871ZM7.2002 7.78711H14.2002V9.78711H7.2002V7.78711Z" />
    </symbol>

    <symbol id="icon-downloadBtn" viewBox="0 0 30 30">
      <path
        d="M25.5752 18.4121V19.8621C25.5752 21.8923 25.5752 22.9074 25.1801 23.6828C24.8326 24.3649 24.278 24.9195 23.5959 25.267C22.8205 25.6621 21.8054 25.6621 19.7752 25.6621H9.62519C7.595 25.6621 6.57991 25.6621 5.80448 25.267C5.12239 24.9195 4.56784 24.3649 4.2203 23.6828C3.8252 22.9074 3.8252 21.8923 3.8252 19.8621V18.4121M20.7419 12.3704L14.7002 18.4121M14.7002 18.4121L8.65853 12.3704M14.7002 18.4121V3.91211"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-exportBtn" viewBox="0 0 24 25">
      <path
        d="M3 21.5H21M12 3.5V17.5M12 17.5L19 10.5M12 17.5L5 10.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-floorPlanBtn" viewBox="0 0 25 25">
      <path
        d="M16.2002 1.78711H23.2002V13.7871M13.2002 5.78711L8.2002 1.78711H1.2002V23.7871H10.2002M10.2002 23.7871H23.2002V13.7871M10.2002 23.7871V13.7871M10.2002 13.7871H13.2002M10.2002 13.7871H7.2002M23.2002 13.7871H19.2002"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </symbol>

    <symbol id="icon-imageBtn" viewBox="0 0 25 25">
      <path
        d="M4.47229 21.515L11.0688 14.9185C11.4648 14.5225 11.6628 14.3245 11.8912 14.2503C12.092 14.185 12.3084 14.185 12.5092 14.2503C12.7375 14.3245 12.9356 14.5225 13.3316 14.9185L19.8841 21.471M14.2002 15.7871L17.0688 12.9185C17.4648 12.5225 17.6628 12.3245 17.8912 12.2503C18.092 12.185 18.3084 12.185 18.5092 12.2503C18.7375 12.3245 18.9355 12.5225 19.3316 12.9185L22.2002 15.7871M10.2002 9.78711C10.2002 10.8917 9.30476 11.7871 8.2002 11.7871C7.09563 11.7871 6.2002 10.8917 6.2002 9.78711C6.2002 8.68254 7.09563 7.78711 8.2002 7.78711C9.30476 7.78711 10.2002 8.68254 10.2002 9.78711ZM7.0002 21.7871H17.4002C19.0804 21.7871 19.9204 21.7871 20.5622 21.4601C21.1267 21.1725 21.5856 20.7136 21.8732 20.1491C22.2002 19.5073 22.2002 18.6673 22.2002 16.9871V8.58711C22.2002 6.90695 22.2002 6.06687 21.8732 5.42514C21.5856 4.86065 21.1267 4.40171 20.5622 4.11409C19.9204 3.78711 19.0804 3.78711 17.4002 3.78711H7.0002C5.32004 3.78711 4.47996 3.78711 3.83822 4.11409C3.27374 4.40171 2.8148 4.86065 2.52718 5.42514C2.2002 6.06687 2.2002 6.90695 2.2002 8.58711V16.9871C2.2002 18.6673 2.2002 19.5073 2.52718 20.1491C2.8148 20.7136 3.27374 21.1725 3.83822 21.4601C4.47996 21.7871 5.32004 21.7871 7.0002 21.7871Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-mapBtn" viewBox="0 0 29 29">
      <path
        d="M6.04102 17.2628C3.80395 18.2496 2.41602 19.6248 2.41602 21.1459C2.41602 24.149 7.82591 26.5834 14.4993 26.5834C21.1728 26.5834 26.5827 24.149 26.5827 21.1459C26.5827 19.6248 25.1947 18.2496 22.9577 17.2628M21.7493 9.66675C21.7493 14.5771 16.3118 16.9167 14.4993 20.5418C12.6868 16.9167 7.24935 14.5771 7.24935 9.66675C7.24935 5.66268 10.4953 2.41675 14.4993 2.41675C18.5034 2.41675 21.7493 5.66268 21.7493 9.66675ZM15.7077 9.66675C15.7077 10.3341 15.1667 10.8751 14.4993 10.8751C13.832 10.8751 13.291 10.3341 13.291 9.66675C13.291 8.9994 13.832 8.45842 14.4993 8.45842C15.1667 8.45842 15.7077 8.9994 15.7077 9.66675Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-playBtn" viewBox="0 0 25 25">
      <path
        d="M12.2002 22.7871C17.723 22.7871 22.2002 18.31 22.2002 12.7871C22.2002 7.26426 17.723 2.78711 12.2002 2.78711C6.67735 2.78711 2.2002 7.26426 2.2002 12.7871C2.2002 18.31 6.67735 22.7871 12.2002 22.7871Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.7002 9.75244C9.7002 9.27516 9.7002 9.03651 9.79994 8.90329C9.88686 8.78718 10.0199 8.71455 10.1646 8.70421C10.3306 8.69236 10.5313 8.8214 10.9328 9.0795L15.6534 12.1142C16.0018 12.3381 16.176 12.4501 16.2361 12.5925C16.2887 12.7169 16.2887 12.8573 16.2361 12.9817C16.176 13.1241 16.0018 13.2361 15.6534 13.4601L10.9328 16.4947C10.5313 16.7528 10.3306 16.8819 10.1646 16.87C10.0199 16.8597 9.88686 16.787 9.79994 16.6709C9.7002 16.5377 9.7002 16.2991 9.7002 15.8218V9.75244Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-shareBtn" viewBox="0 0 25 25">
      <path
        d="M9.09 14.01L15.92 17.99M15.91 7.01L9.09 10.99M21.5 5.5C21.5 7.15685 20.1569 8.5 18.5 8.5C16.8431 8.5 15.5 7.15685 15.5 5.5C15.5 3.84315 16.8431 2.5 18.5 2.5C20.1569 2.5 21.5 3.84315 21.5 5.5ZM9.5 12.5C9.5 14.1569 8.15685 15.5 6.5 15.5C4.84315 15.5 3.5 14.1569 3.5 12.5C3.5 10.8431 4.84315 9.5 6.5 9.5C8.15685 9.5 9.5 10.8431 9.5 12.5ZM21.5 19.5C21.5 21.1569 20.1569 22.5 18.5 22.5C16.8431 22.5 15.5 21.1569 15.5 19.5C15.5 17.8431 16.8431 16.5 18.5 16.5C20.1569 16.5 21.5 17.8431 21.5 19.5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-buildingBtn" viewBox="0 0 29 29">
      <path
        d="M6.04167 10.8751V20.5417M11.4792 10.8751V20.5417M17.5208 10.8751V20.5417M22.9583 10.8751V20.5417M3.625 22.4751L3.625 23.4417C3.625 24.1185 3.625 24.4568 3.7567 24.7153C3.87255 24.9427 4.0574 25.1275 4.28476 25.2434C4.54324 25.3751 4.8816 25.3751 5.55833 25.3751H23.4417C24.1184 25.3751 24.4568 25.3751 24.7152 25.2434C24.9426 25.1275 25.1275 24.9427 25.2433 24.7153C25.375 24.4568 25.375 24.1185 25.375 23.4417V22.4751C25.375 21.7983 25.375 21.46 25.2433 21.2015C25.1275 20.9741 24.9426 20.7893 24.7152 20.6734C24.4568 20.5417 24.1184 20.5417 23.4417 20.5417H5.55833C4.8816 20.5417 4.54324 20.5417 4.28476 20.6734C4.0574 20.7893 3.87255 20.9741 3.7567 21.2015C3.625 21.46 3.625 21.7983 3.625 22.4751ZM14.0806 3.71826L5.13893 5.7053C4.59874 5.82535 4.32864 5.88537 4.12702 6.03062C3.94918 6.15874 3.80955 6.33281 3.72306 6.53421C3.625 6.76253 3.625 7.03922 3.625 7.5926L3.625 8.94173C3.625 9.61846 3.625 9.95683 3.7567 10.2153C3.87255 10.4427 4.0574 10.6275 4.28476 10.7434C4.54324 10.8751 4.8816 10.8751 5.55833 10.8751H23.4417C24.1184 10.8751 24.4568 10.8751 24.7152 10.7434C24.9426 10.6275 25.1275 10.4427 25.2433 10.2153C25.375 9.95683 25.375 9.61846 25.375 8.94173V7.5926C25.375 7.03922 25.375 6.76253 25.2769 6.53421C25.1905 6.33281 25.0508 6.15874 24.873 6.03062C24.6714 5.88537 24.4013 5.82535 23.8611 5.7053L14.9194 3.71826C14.7629 3.68348 14.6846 3.66609 14.6056 3.65916C14.5353 3.653 14.4647 3.653 14.3944 3.65916C14.3154 3.66609 14.2371 3.68348 14.0806 3.71826Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>
  </svg>
)

SvgSpriteButtons.defaultProps = defaultProps

export default SvgSpriteButtons
